/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const scrollToElement = require('scroll-to-element');

const checkHash = (location) => {
    const { hash } = location;
    if (hash) {
        scrollToElement(hash, {
            offset: 0,
            duration: 100,
        });
    }
};

exports.onRouteUpdate = ({ location }) => {
    checkHash(location);
};

exports.onServiceWorkerUpdateReady = () => {
    window.location.reload();
};
